import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import '../../css/structure.css'; // Import du fichier CSS
import jsPDF from "jspdf";

import "jspdf-autotable";
import * as XLSX from "xlsx";
import { useLayoutEffect } from 'react';
import { useInsertionEffect } from 'react';
import { safeUseLayoutEffect } from 'react-table';
const chargement = require("../images/loadingcarre.gif");
const telechargerModal = require("../images/telechargerModal.png");
const update_ = require("../images/update-somme.png");
const update_gif = require("../images/update-somme.gif");

const S_Itableindicateur_2 = () => {
  const [sousDomaines, setSousDomaines] = useState([]);
  const [selectedSousDomaine, setSelectedSousDomaine] = useState('');
  const [annee, setAnnee] = useState([]);
  const [selectedAnnee, setSelectedAnnee] = useState('');

  const [selectedNomdistrict, setSelectedNomdistrict] = useState('');
  const [niveaudistrict, setNiveaudistrict] = useState([]);
  const [selectedNiveaudistrict, setSelectedNiveaudistrict] = useState('');
  const [factacessData, setFactacessData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [format, setFormat] = useState("");
  const [recherche, setRecherche] = useState("");
  const [actualizer, setActualizer] = useState(1);


 
   // Charger les données pour les options de filtres
   useEffect(() => {
    axios.get('https://precvadithies.sn/sous-domaine-sante').then(response => setSousDomaines(response.data));
    axios.get('https://precvadithies.sn/annee-sante').then(response => setAnnee(response.data));
    axios.get('https://precvadithies.sn/district').then(response => {setNiveaudistrict(response.data);});
  }, []);
  
const rafraichir = () =>{
 

      setLoading(true);
      axios.get('https://precvadithies.sn/somme-sante')
        .then((response) => {
          setFactacessData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Erreur lors du chargement des données:', error);
          setLoading(false);
          setFactacessData([]);
        });
 
}
  // Charger toutes les données par défaut
  useEffect(() => {
    setLoading(true);
    axios.get('https://precvadithies.sn/somme-sante')
      .then((response) => {
        setFactacessData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des données:', error);
        setLoading(false);
        setFactacessData([]);
      });
  }, []);

  useEffect(() => {
   
    if (selectedNiveaudistrict || selectedAnnee || selectedSousDomaine  ) {
      setLoading(true);
      const url = `https://precvadithies.sn/somme-sante?get=${selectedAnnee}&niveau_totaux=${selectedNiveaudistrict}&sousdomaine_totaux=${selectedSousDomaine}`;
      axios.get(url)
        .then((response) => {
          console.log('C...c...c....c');
       
          setFactacessData(response.data);
          setLoading(false);
          
          
        })
        .catch((error) => {
          console.error('Erreur lors de l\'insertion de la structure:', error);
          // Gérer l'erreur ici aussi, par exemple en affichant un message d'erreur
          setLoading(false); // Assurez-vous de désactiver le chargement même en cas d'erreur
          setFactacessData([]);
          console.log('erreur ici');
          
        });
    }
  }, [selectedNiveaudistrict, selectedAnnee, selectedSousDomaine]);
  

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Confirmer la suppression?',
      text: 'Cette action est irréversible!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, supprimer!',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`https://precvadithies.sn/totaux-sante/${id}`)
          .then(() => {
            setFactacessData((prev) => prev.filter((item) => item.id_totaux !== id));
            Swal.fire('Supprimé!', "L'élément a été supprimé.", 'success');
          });
      }
    });
  };

  const handleEdit = (item) => {
    Swal.fire({
      title: 'Modifier la valeur',
      input: 'text',
      inputValue: item.valueStructure,
      showCancelButton: true,
      confirmButtonText: 'Enregistrer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        const newValue = result.value;
        axios.put(`https://precvadithies.sn/totaux-sante/${item.id_totaux}`, { value_structure : newValue })
          .then(() => {
            setFactacessData((prev) =>
              prev.map((data) => (data.id_totaux === item.id_totaux ? { ...data, valeur_totaux: newValue } : data))
            );
            Swal.fire('Modifié!', 'La valeur a été mise à jour.', 'success');
          });
      }
    });
  };

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: 'Chargement des données en cours...',
        text: 'Veuillez patienter',
        html: `<img src="${chargement}" />`,
        background: "#F1F2F3",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    } else {
      Swal.close();
    }
  }, [loading]);

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
  };

  const downloadFile = () => {
    if (format === "pdf") downloadPDF();
    else if (format === "excel") downloadExcel();
    else alert("Veuillez sélectionner un format");
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
  
    // Titre principal centré
    doc.setFontSize(16);
    doc.text(`Donnée sur l'education ${selectedSousDomaine}`, 105, 20, { align: 'center' });
  
    // Sous-titres à gauche et à droite
    doc.setFontSize(12);
    doc.text(`niveau ${selectedNiveaudistrict}`, 20, 30);
    doc.text(`Pour l'année ${selectedAnnee}`, 180, 30, { align: 'right' });
  
    // Titre du tableau centré
    doc.setFontSize(14);
    doc.text(`Nom de l'etablissement : ${selectedNomdistrict}`, 105, 40, { align: 'center' });
  
    // Ajouter le tableau avec des couleurs alternées
    doc.autoTable({
      startY: 50, // Position de départ du tableau
      head: [["Valeur Indicateur", "Valeur"]],
      body: filteredData.map(item => [
        item.indicateur_totaux,
        item.valeur_totaux,
      ]),
      styles: {
        fillColor: [220, 220, 220], // Couleur de fond par défaut des lignes
        textColor: [0, 0, 0],
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Couleur de fond alternée pour les lignes impaires
      },
      headStyles: {
        fillColor: [0, 102, 204], // Couleur de fond pour l'en-tête du tableau
        textColor: [255, 255, 255],
      },
    });
  
    // Téléchargement du fichier PDF avec le nom dynamique
    doc.save(`${selectedSousDomaine}.pdf`);
  };

  const downloadExcel = () => {
    const filteredColumns = filteredData.map(({ indicateur_totaux, valeur_totaux }) => ({
      indicateur_totaux, valeur_totaux
    }));
    const worksheet = XLSX.utils.json_to_sheet(filteredColumns);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table");
    XLSX.writeFile(workbook, `${selectedSousDomaine}_${selectedNomdistrict}_${selectedNiveaudistrict}_${selectedAnnee}.xlsx`);
  };
  
  const filteredData = factacessData.filter((item) =>
    item.indicateur_totaux.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Obtenir les niveaux d'école uniques
const uniqueNiveaux = Array.from(new Set(niveaudistrict.map(sd => sd.niveaudistrict)));

const [gif, setGif] = useState(false);


const helloWorld = async () => {
 
  gif ? setGif(false) : setGif(true);
  setTimeout(() => {
    setGif(false)
  }, 2000);
  setTimeout(async () => {
  let headersList = {
    "Accept": "*/*",
    "User-Agent": "Thunder Client (https://www.thunderclient.com)",
    "params": "" 
   }
   
   let reqOptions = {
     url: "https://precvadithies.sn/somme-sante",
     method: "POST",
     headers: headersList,
   }
   
   let response = await axios.request(reqOptions);
   //actualizer == 1 ? setActualizer(2) : setActualizer(1)
  
   Swal.fire(response.data.message, `Nombre de ligne(s) mis à jour : <strong>${response.data.lignesMiseAJour},</strong> Nombre de ligne(s) nouvellement insérée(s) : <strong>${response.data.lignesInserees}</strong> `, 'success');
   
  }, 3000);
 setTimeout(() => {
  rafraichir()
 }, 6000);
 
    
 
}
  return (
    <div className="app-container">
      {loading ? (
        <p>Chargement...</p>
      ) : (
        <>
           <div style={{ display: "flex", marginBottom: "10px", marginTop: "3px", gap:"10px" }}>
            <select className='select_and_search' onChange={(e) => setSelectedSousDomaine(e.target.value)} value={selectedSousDomaine}>
              <option value="">Tous les sous-domaines</option>
              {sousDomaines.map(sd => (
                <option key={sd.valeursousdomaine} value={sd.valeursousdomaine}>
                  {sd.valeursousdomaine}
                </option>
              ))}
            </select>
            <select className='select_and_search' onChange={(e) => setSelectedNiveaudistrict(e.target.value)} value={selectedNiveaudistrict}>
              <option value="">Tous les niveaux</option>
              {[...new Set(niveaudistrict.map(sd => sd.niveauDistrict))].map(niveau => (
                <option key={niveau} value={niveau}>
                  {niveau}
                </option>
              ))}
            </select>
            <select className='select_and_search' onChange={(e) => setSelectedAnnee(e.target.value)} value={selectedAnnee}>
              <option value="">Toutes les années</option>
              {annee.map(sd => (
                <option key={sd.valeurAnnee} value={sd.valeurAnnee}>
                  {sd.valeurAnnee}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className='select_and_search'
            />
             <img onClick={()=> helloWorld()} src={!gif ? update_ : update_gif} title='Click pour actualiser' style={{width:'35px', height:"35px",
               cursor:"pointer", border:"1px solid green", marginTop:"3px"}}/> 
          </div>
          <div className="table-container">
            <table className="styled-table">
              <thead>
                <tr>
                 
                  <th>Indicateur</th>
                  
                  <th>Valeur</th>
                 {/* <th style={{minWidth:"40vh"}} >Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item) => (
                    <tr key={item.id_totaux}>
                      <td>{item.indicateur_totaux}</td>
                     
                      <td>{item.valeur_totaux}</td>
                    {/*  <td>
                        <button onClick={() => handleEdit(item)} className="edit-btn">Modifier</button>
                        <button onClick={() => handleDelete(item.id_totaux)} className="delete-btn">Supprimer</button>
                      </td>*/}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>Aucune ligne trouvée</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div style={{ display: "flex", justifyContent:"center", alignItems:"center" }}>
              <select className='select_and_search' value={format} onChange={handleFormatChange}>
                <option value="">Choisir un format</option>
                <option value="pdf">PDF</option>
                <option value="excel">Excel</option>
              </select>
              <img
        style={{ cursor: "pointer", marginBottom: "10px" }}
        onClick={downloadFile}
        title="Télécharger"
        alt="precvadi"
        src={telechargerModal}
        fluid
        rounded
        width="60px"
        height="60px"
        id="telecharger"
      />
      <span for="telecharger" >Télécharger ici</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default S_Itableindicateur_2;