import React, { useRef, useState } from "react";
import "../../css/indicateurs.css"
import NavBar from "../NavBar";

import Modal from "react-modal";
import Footer from "../Footer";
import { Button } from "react-bootstrap";
import Itableindicateur from "./Itableindicateur";
import Itableindicateur_2 from "./Itableindicateur_2";
import S_Itableindicateur from "./S_Itableindicateur";
import S_Itableindicateur_2 from "./S_Itableindicateur_2";
import Itableindicateur_totaux from "./Itableindicateur_totaux";

const fermerModal = require("../images/fermerModal.png");
const tableicon = require("../images/itable.png");
const educicon = require("../images/educicon.png");
const santeicon = require("../images/educsante.png");

export const Sante = () =>{



  return(
    <>

    <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`}}>
      <div className="spaced-div fordiv"> 
        <h2 className="fordiv_contain">Alimentation et nutrition</h2>
      </div>
      <div className="spaced-div stl2">
        <iframe className="stl3" title="boulel_accespart" src="https://app.powerbi.com/view?r=eyJrIjoiYWY2M2FhNjctNWRiMi00NzI1LTk5ZjQtOTY0Y2FiM2EyZmRkIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>
    </div>

    <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`}}>
      <div className="spaced-div fordiv"> 
        <h2 className="fordiv_contain">Planification familiale</h2>
      </div>
      <div className="spaced-div stl2">
        <iframe className="stl3" title="boulel_accespart" src="https://app.powerbi.com/view?r=eyJrIjoiNDA5MTMyMjItMTEzNi00ZWM5LTkzOWQtODEwY2Q4MzFkZmY5IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>
    </div>

    <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`}}>
      <div className="spaced-div fordiv"> 
        <h2 className="fordiv_contain">Ressources humaines</h2>
      </div>
      <div className="spaced-div stl2">
        <iframe className="stl3" title="boulel_accespart" src="https://app.powerbi.com/view?r=eyJrIjoiOTYzZjg5MTItYzcwMi00M2I2LThkOTQtZjZlYTU4MGJjMzFjIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>
    </div>

    <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`}}>
      <div className="spaced-div fordiv"> 
        <h2 className="fordiv_contain">Santé de la mère et du nouveau-né</h2>
      </div>
      <div className="spaced-div stl2">
        <iframe className="stl3" title="boulel_accespart" src="https://app.powerbi.com/view?r=eyJrIjoiNDJmYTQ0MTQtOTA3Mi00YzUzLWJjMWEtZjJlNjY5ZDZlNWI0IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>
    </div>

    <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`}}>
      <div className="spaced-div fordiv"> 
        <h2 className="fordiv_contain">Survie de l'enfant</h2>
      </div>
      <div className="spaced-div stl2">
        <iframe className="stl3" title="boulel_accespart" src="https://app.powerbi.com/view?r=eyJrIjoiMzRjMGEzNGQtYTc3Mi00NmZjLWExYzAtYjFlOGUwNTkwMzY5IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>
    </div>

    <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`}}>
      <div className="spaced-div fordiv"> 
        <h2 className="fordiv_contain">Vaccination de routine</h2>
      </div>
      <div className="spaced-div stl2">
        <iframe className="stl3" title="boulel_accespart" src="https://app.powerbi.com/view?r=eyJrIjoiODAzM2Q3ZGYtZTFkMi00ZTE5LWIzMDQtZWNhOTdhNGFmMWM3IiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>
      </div>
    </div>
    </>
  )
}

export const Education = () => {

  
  return(
    <>
    <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`,height:"auto"}}>
      <div className="spaced-div fordiv"> 
        <h2 className="fordiv_contain">Accès et participation</h2>
      </div>
      <div className="spaced-div stl2" style={{border:"none"}} >
      <iframe title="boulel_accespart_3b" style={{height:"620px",border:"none",overflowY:"hidden"}}  src="https://app.powerbi.com/view?r=eyJrIjoiOGVkNWUyOWEtYWNhYi00OWI5LWFiNjgtMWEwM2QyY2FmYjYxIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" className="stl3"  frameborder="0" allowFullScreen="true"></iframe>
       {/**  <iframe className="stl3" style={{height:"620px"}} title="boulel_accespart" src="https://app.powerbi.com/view?r=eyJrIjoiNDc3YzcyMWEtOGM0Zi00Y2U2LWE5ZTktN2Y3Y2VlM2M3ZjkwIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9" frameborder="0" allowFullScreen="true"></iframe>*/}
      </div>
    </div>
      

      <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`}}>
        <div className="spaced-div fordiv"> 
          <h2 className="fordiv_contain">Qualité</h2>
        </div>
        <div className="spaced-div stl2" style={{border:"none"}}>
          <iframe className="stl3" title="boulel_gouvernance_3b" style={{height:"620px",border:"none",overflowY:"hidden"}} src="https://app.powerbi.com/view?r=eyJrIjoiNTEyMjc5MGEtOGIyOC00YjczLWI3NmItYzhiODBlNDhmZWRjIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"  frameborder="0" allowFullScreen="true"></iframe>
        </div>
      </div>
      

      <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`}}>
        <div className="spaced-div fordiv"> 
          <h2 className="fordiv_contain">Environnement</h2>
        </div>
        <div className="spaced-div stl2" style={{border:"none"}}>
          <iframe style={{height:"620px",border:"none",overflowY:"hidden"}} className="stl3" title="boulel_environnement_3b"  src="https://app.powerbi.com/view?r=eyJrIjoiZTNlNDY1ZjctNTlmZS00M2FhLWEwNGEtODNjYTA4NGU3MzUwIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"  frameborder="0" allowFullScreen="true"></iframe>
        </div>
      </div>
      

      <div className="w-100 d-flex justify-content-center align-items-center" style={{flexDirection:`column`}}>
        <div className="spaced-div fordiv"> 
          <h2 className="fordiv_contain">Qualification</h2>
        </div>
        <div className="spaced-div stl2" style={{border:"none"}}>
          <iframe className="stl3"  style={{height:"620px",border:"none",overflowY:"hidden"}} title="boulel_qualification_3b"  src="https://app.powerbi.com/view?r=eyJrIjoiOWZkNDgxNDEtNjM0Yi00NWQ5LWFhOGEtZmMzYjMzZWVlZjYxIiwidCI6ImJmZTY0YzY0LWIwY2UtNDJiZC04MDlhLWE4ODlhMjJhZGQwOSJ9"  frameborder="0" allowFullScreen="true"></iframe>
        </div>
      </div>



      
      

    </>
  )
}


export const Indicateur = () => {
   //FOR MODAL TABLE BOU BAKH BI
 const [modalIsOpen, setIsOpen] = useState(false);
 const [activeTab, setActiveTab] = useState('structure');
 const [edu, setEdu] = useState(true);
 const openModal = () => {
   setIsOpen(true);
 };

 const closeModal = () => {
   setIsOpen(false);
 };
  const [isEducation, SetEducation] = React.useState(true);
  const [isSante, SetSanté] = React.useState(false);
  const HandleEducation = () => {
    SetEducation(true);
    SetSanté(false);
    console.log("test education");
  };
  const HandleSante = () => {
    SetSanté(true);
    SetEducation(false);
    console.log("test sante ");
  };

  return (
    <>
    <NavBar />
    <div className="w-100">
      
        <div className="w-100 d-flex justify-content-center align-items-center">
          <div className="spaced-div sous">
            <h2 className="title1">Sous domaine</h2>
          </div>
        </div>
            <div className="card11">
              <Button
                className='btnIcon11'
                style={{
                   border:"1px solid white",
                  backgroundColor: isEducation ? "#316685" : "#7b9bad",
                }}
                onClick={HandleEducation}
              >
                <img
                  alt="precvadi"
                  src={educicon}
                  className="carouselicon11"
                  fluid
                  rounded
                />
                <h5 className='titreES11'>Education</h5>
              </Button>
              <Button
                className='btnIcon11'
                style={{
                   border:"1px solid white",
                  backgroundColor: isSante ? "#316685" : "#7b9bad",
                }}
                type="button"
                onClick={HandleSante}
              >
                <img
                  alt="precvadi"
                  src={santeicon}
                  className="carouselicon11"
                  fluid
                  rounded
                />
                <h5 className='titreES11'>Sante</h5>
              </Button>
              <Button
              className='btnIcon11'
                style={{
                  borderColor: "#fff",
                  border: "1px solid #131C85",
                  backgroundColor:"white",
                  color:"black"
                }}
                type="button"
                onClick={openModal}
              >
                <img
                  alt="precvadi"
                  src={tableicon}
                  className="carouselicon11"
                  fluid
                  rounded
                />
                <h5 className='titreES11' >Tableau</h5>
                
              

              </Button>
            </div>
            
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              style={{
                
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                },
                content: {
                  position: "relative",
                  top: "55%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  width: "90%",
                  padding: "10px",
                  zIndex:"10000"
                },
              }}
            >
              <span
        style={{
          fontSize: "20px",
          backgroundColor: "rgba(0, 128, 1, 0.15)",
          color: "#008001",
          marginBottom: "5px",
          borderBottom: activeTab === 'structure' ? "2px solid #008001" : "none",
          cursor: "pointer",
          padding: "7px",
          display: edu ? "inline-block": "none"
        }}
        onClick={() => setActiveTab('structure')}
      >
        Données par structure
      </span>
      {/*
      <span
        style={{
          fontSize: "20px",
          backgroundColor: "rgba(0, 128, 1, 0.15)",
          color: "#008001",
          marginLeft: "10px",
          marginBottom: "5px",
          borderBottom: activeTab === 'communal' ? "2px solid #008001" : "none",
          cursor: "pointer",
          padding: "7px"
        }}
        onClick={() => setActiveTab('communal')}
      >
        Données communale
      </span>
*/}
      <span
        style={{
          fontSize: "20px",
          backgroundColor: "rgba(0, 128, 1, 0.15)",
          color: "#008001",
          marginLeft: "10px",
          marginBottom: "5px",
          borderBottom: activeTab === 'communal_totaux' ? "2px solid #008001" : "none",
          cursor: "pointer",
          padding: "7px",
          display: edu ? "inline-block": "none"
        }}
        onClick={() => setActiveTab('communal_totaux')}
      >
        Données communale 
      </span>

      <span
        style={{
          fontSize: "20px",
          backgroundColor: "rgba(0, 128, 1, 0.15)",
          color: "#008001",
          marginBottom: "5px",
          marginLeft: "10px",
          borderBottom: activeTab === 's_structure' ? "2px solid #008001" : "none",
          cursor: "pointer",
          padding: "7px",
          display: !edu ? "inline-block": "none"
        }}
        onClick={() => setActiveTab('s_structure')}
      >
        Santé structure
      </span>
      <span
        style={{
          fontSize: "20px",
          backgroundColor: "rgba(0, 128, 1, 0.15)",
          color: "#008001",
          marginLeft: "10px",
          marginBottom: "5px",
          borderBottom: activeTab === 's_communal' ? "2px solid #008001" : "none",
          cursor: "pointer",
          padding: "7px",
          display: !edu ? "inline-block": "none"
        }}
        onClick={() => setActiveTab('s_communal')}
      >
        Santé commune
      </span>
      
      
      
      <div style={{position:"absolute",right:"20px"}} >
      <Button
                className='btnIcon11'
                style={{
                   border:"1px solid white",
                  backgroundColor: edu ? "#131C85" : "#7b9bad",
                }}
                onClick={()=>{setEdu(true); setActiveTab('structure')}}
              >
                <img
                  alt="precvadi"
                  src={educicon}
                  className="carouselicon11"
                  fluid
                  rounded
                />
                <h5 className='titreES11'>Education</h5>
              </Button>
              <Button
                className='btnIcon11'
                style={{
                   border:"1px solid white",
                  backgroundColor: !edu ? "#131C85" : "#7b9bad",
                }}
                type="button"
                onClick={()=>{setEdu(false); setActiveTab('s_structure')}}
              >
                <img
                  alt="precvadi"
                  src={santeicon}
                  className="carouselicon11"
                  fluid
                  rounded
                />
                <h5 className='titreES11'>Sante</h5>
              </Button>
              
      </div>

      
              <div>
               
                {activeTab == "structure" ? <Itableindicateur /> :
           /* activeTab == "communal" ? <Itableindicateur_2 />:*/
            activeTab == "communal_totaux" ? <Itableindicateur_totaux />:
            activeTab == "s_structure" ? <S_Itableindicateur /> :
            activeTab == "s_communal" && <S_Itableindicateur_2 />
          }
              </div>

              <img
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
                onClick={closeModal}
                alt="precvadi"
                src={fermerModal}
                title="fermer"
                fluid
                rounded
                width="30px"
                heigth="30px"
              />
            </Modal>
           
         
          {isEducation && <Education />}
          {isSante && <Sante />}
     
     
      
    </div>
    <Footer />
    </>
  );
};